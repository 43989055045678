<template>
  <div>
    <my-nav-bar
      title="订单明细"
      left-text="返回"
      left-arrow
    />
    <div class="search-bar">
      <form action="/">
        <van-search
          v-model="listQuery.name"
          placeholder="请输入客户名称"
          clearable
          @search="handleSearch"
          @clear="handleSearch"
        />
      </form>
    </div>
    <div class="app-main-search-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="loadData"
      >
        <van-cell
          v-for="order in list"
          :key="order.id"
          class="order"
          :title="order.num"
          :value="`￥${order.amount}`"
          :label="order.client.name"
          is-link
          center
          @click="toOrderGoodsView(order.id)"
        />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '../../components/my-nav-bar.vue'
import { getOrders } from '@/api/bill_check'
export default {
  name: 'BillCheckOrder',
  components: { myNavBar },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0,
        limit: 10,
        name: '',
        bill_id: this.$route.query.bill_id
      },
      showEmpty: false
    }
  },
  methods: {
    handleSearch() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    loadData() {
      this.listQuery.page++
      getOrders(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    toOrderGoodsView(orderId) {
      this.$router.push({
        path: 'bill-check-order-goods',
        query: {
          bill_id: this.listQuery.bill_id,
          order_id: orderId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/color';
  .list {
    padding-top: 10px
  }
  .order {
    .van-cell__value {
      color: $amountRedColor;
    }
  }
  .search-bar {
      z-index: 1;
      position: fixed;
      top: 46px;
      left: 0;
      right: 0;
    }
</style>
